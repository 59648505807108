/*_Color Variables_*/

$warning: #ff0000;
$success: #2b842b;
$complementary-color:hsl(0, 0%, 100%);
$grey-note: #787878;
$default-color: #000000;
$primary-color: #001848;
$secondary-color:#083f88;
$light-blue-color: #00367A;
$light-white-color: #F2F2F2;
$border-color: #3333331e;
$text-blue: #00173D;
$alert-positive: #1AB85B;
$neutral-black: #333333;
$golden-color: #d1ab3e;
$facebook: #3a589b;
$twitter: #1da1f2;
$gplus: #dc4538;
$white:#ffffff;
$instagram: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
$gray-light:#F7F7FA;
$inactive-color: #959595;
$full-space: 15px;
$two-space: 30px;
$half-space: 7.5px;
/*_Font Variables_*/

// $img-path: "../static-assets/images/cssimages/svg/icon-video.svg";
$regular_font-family:"NoirProRegular",
sans-serif;
$bold_font-family:"NoirProBold",
sans-serif;
$medium_font-family:"NoirProMedium",
sans-serif;
$light_font-family:"NoirProLight",
sans-serif;
$semibold_font-family: "NoirProSemiBold",
sans-serif;
$delivery-bold:"Delivery",
sans-serif;
$waf-icon: "waf-icon-font";